import Pagination from '@material-ui/lab/Pagination'
import {PaginationProps} from '@material-ui/lab/Pagination/Pagination'
import classes from './styles.module.scss'
import clsx from 'clsx'

export default function AppPagination(props: {
  count: number
  page: number
  disabled?: boolean
  spaceBetween?: boolean
  onChange?: (event: React.ChangeEvent<unknown>, value: number) => void
  otherProps?: PaginationProps
}) {
  const {
    count,
    page,
    disabled,
    onChange,
    otherProps = {},
    spaceBetween = false,
  } = props

  return (
    <Pagination
      disabled={disabled}
      count={count}
      page={page}
      color="primary"
      onChange={onChange}
      className={clsx(
        classes.paginationWrapper,
        spaceBetween ? '-between' : '',
      )}
      {...otherProps}
    />
  )
}
