import {PropertyCardType} from 'src/types/property'
import {PageType} from 'src/types/page'
import PropertyCard from 'src/components/modules/property/card/PropertyCard'
import PagePagination from './PagePagination'
import TiredOfSearchingCta from 'src/components/modules/cta/TiredOfSearchingCta'
import {useSmallSize} from 'src/hooks/screenSize'

function PageProperties(props: {page: PageType}) {
  const {page} = props
  const isSmallSize = useSmallSize()

  if (page.properties.length === 0) {
    return null
  }

  const slicePosition = isSmallSize ? 4 : 3
  const firstSectionPopularProperties = page.properties.slice(0, slicePosition)
  const secondSectionPopularProperties = page.properties.slice(slicePosition)

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {firstSectionPopularProperties.map((property: PropertyCardType) => (
          <PropertyCard key={property.id} data={property} />
        ))}
        <div className="sm:h-full">
          <TiredOfSearchingCta size="small" className="h-full" />
        </div>
        {secondSectionPopularProperties.map((property: PropertyCardType) => (
          <PropertyCard key={property.id} data={property} />
        ))}
      </div>
      <PagePagination page={page} />
    </div>
  )
}

export default PageProperties
